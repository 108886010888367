<template>
    <div>
        <div v-if="authUserPermission['stock-calc-fast-by-demand-multiple']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>

            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row align-items-center">
                            <div class="col-12 col-md-6">
                                <h3 class="text-start">Rekomendasi Stock Sparepart Optimum for Multiple kode material
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="table-wrapper">
                            <div class="mt-3" style="display: flex; align-items: flex-start;">
                                <!-- Buttons -->
                                <div style="margin-right: 10px; margin-top: 29px;">
                                    <label class="form-control-label" style="visibility: hidden;">&nbsp;</label>
                                    <base-button size="md" type="primary" @click="openInputMaterialsDialog">
                                        Input Materials
                                    </base-button>
                                </div>
                                <div style="margin-top: 29px;">
                                    <label class="form-control-label" style="visibility: hidden;">&nbsp;</label>
                                    <base-button type="warning" @click="openMatrixDialog">
                                        Matrix Perhitungan
                                        <el-tooltip
                                            content="Memunculkan matrix kebutuhan data untuk tipe material data"
                                            placement="top">
                                            <sup>
                                                <span style="font-size:10px"><i class="fa fa-exclamation-circle"></i>
                                                </span>
                                            </sup>
                                        </el-tooltip>
                                        <i class="el-icon-s-marketing"></i>
                                    </base-button>
                                </div>
                            </div>

                            <!-- Kondisi sebelum data diunggah -->
                            <skeleton-loading v-if="loadingSkeleton && !tableUpload.length"></skeleton-loading>
                            <empty v-else-if="!loadingSkeleton && !tableUpload.length"></empty>

                            <!-- Tabel untuk menampilkan data dari file Excel -->
                            <div v-if="!loadingSkeleton && !loadingCalculate && !isUploading && tableUpload.length" class="table-container mt-3" :style="{ height: table1Height + 'px' }">
                                <el-table :data="tableUpload" style="width: 100%" @selection-change="handleSelectionChange" :max-height="computeTableHeight()" border>
                                    <el-table-column type="selection" width="55"></el-table-column>

                                    <!-- Kolom No -->
                                    <el-table-column prop="no" label="No" min-width="70">
                                        <template v-slot:header>
                                            <div class="table-header-center">No</div>
                                        </template>
                                        <template v-slot:scope="{ row, $index }">
                                            <el-input v-model="row.no" :value="$index + 1" 
                                                placeholder="Enter No"
                                                readonly
                                            ></el-input>
                                        </template>
                                    </el-table-column>

                                    <!-- Plant -->
                                    <el-table-column v-if="this.authUserRole['Master Data PI']" prop="plant" label="Plant" min-width="170">
                                        <template v-slot:header>
                                            <div class="table-header-center">
                                                Plant
                                                <el-tooltip class="item" effect="dark"
                                                    content="Anak perusahaan dalam PI Grup" placement="top">
                                                    <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                        <i class="fa fa-exclamation-circle"></i>
                                                    </span>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                        <template v-slot="scope">
                                            <span>{{ scope.row.plant }}</span>
                                        </template>
                                    </el-table-column>

                                    <!-- Material code -->
                                    <el-table-column prop="material_code" label="Material" min-width="170">
                                        <template v-slot:header>
                                            <div class="table-header-center">
                                                Material Code
                                                <el-tooltip class="item" effect="dark"
                                                    content="Material Spare Part MRO (6XXXXX)" placement="top">
                                                    <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                        <i class="fa fa-exclamation-circle"></i>
                                                    </span>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                        <template v-slot="scope">
                                            <span>{{ scope.row.material_code }}</span>
                                        </template>
                                    </el-table-column>

                                    <!-- Deskripsi -->
                                    <el-table-column prop="deskripsi" label="Deskripsi" min-width="300">
                                        <template v-slot:header>
                                            <div class="table-header-center">
                                                Deskripsi
                                                <el-tooltip placement="top">
                                                    <template #content>Deskripsi Material</template>
                                                    <sup>
                                                        <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                            <i class="fa fa-exclamation-circle"></i>
                                                        </span>
                                                    </sup>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                        <template v-slot="scope">
                                            <span :class="scope.row.deskripsi.includes('tidak ditemukan') ? 'text-danger' : ''">{{ scope.row.deskripsi }} </span>
                                        </template>
                                    </el-table-column>

                                    <!-- Stock Non Stock -->
                                    <el-table-column prop="stockNonStock" label="Stock Strategy" min-width="175">
                                        <template v-slot:header>
                                            <div class="table-header-center">
                                                Stock Strategy
                                            </div>
                                            <el-tooltip placement="top">
                                                <template #content>
                                                    Strategi penyimpanan suatu material <br> 
                                                    berdasarkan stock holding Policy (SHP)
                                                </template>
                                                <sup>
                                                    <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                        <i class="fa fa-exclamation-circle"></i>
                                                    </span>
                                                </sup>
                                            </el-tooltip>
                                        </template>
                                        <template v-slot="scope">
                                            <span>{{ scope.row.stockNonStock || '-' }}</span>
                                        </template>
                                    </el-table-column>

                                    <!-- Metode -->
                                    <el-table-column prop="metode" label="Metode" min-width="240">
                                        <template v-slot:header>
                                            <div class="table-header-center">
                                                Metode
                                                <el-tooltip placement="top">
                                                    <template #content>
                                                        Metode yang digunakan untuk melakukan perhitungan, <br>
                                                        disesuaikan dengan karakteristik demand dari sparepart
                                                    </template>
                                                    <sup>
                                                        <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                            <i class="fa fa-exclamation-circle"></i>
                                                        </span>
                                                    </sup>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                        <template v-slot="scope">
                                            <div v-if="scope.row.metode && scope.row.metode.length > 1">
                                                <ol style="padding-left: 15px; margin-bottom: 0px; padding-bottom: 0px">
                                                    <li v-for="(metode, index) in scope.row.metode" :key="index" :class="isFieldReturnOfRunCalc(scope.row, 'metode', scope.$index) ? 'colorValueMetode' : ''">
                                                        {{ metode }}
                                                    </li>
                                                </ol>
                                            </div>
                                            <div v-else-if="scope.row.metode && scope.row.metode.length === 1">
                                                <ol style="list-style-type: none; padding-left: 0px; margin-bottom: 0px; padding-bottom: 0px">
                                                    <li v-for="(metode, index) in scope.row.metode" :key="index" :class="isFieldReturnOfRunCalc(scope.row, 'metode', scope.$index) ? 'colorValueMetode' : ''">
                                                        {{ metode }}
                                                    </li>
                                                </ol>
                                            </div>
                                            <span v-else >-</span>
                                        </template>
                                    </el-table-column>

                                    <input type="hidden" id="stare-after-run-calc">
                                    <!-- SAFETY STOCK -->
                                    <el-table-column prop="safety_stock" label="Safety Stock" min-width="200">
                                        <template v-slot:header>
                                            <div class="table-header-center">
                                                Safety Stock
                                                <el-tooltip class="item" effect="dark" placement="top">
                                                    <template #content>
                                                        Persediaan pengaman (buffer stock), yang berguna <br />
                                                        untuk menjaga kemungkinan terjadinya Stock Out <br />
                                                        yang diakibatkan karena penggunaan material yang <br />
                                                        melebihi perkiraan semula atau dikarenakan Lead <br />
                                                        Time pengiriman material yang lebih lama <br />
                                                        dari yang diperkirakan.
                                                    </template>
                                                    <sup>
                                                        <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                            <i class="fa fa-exclamation-circle"></i>
                                                        </span>
                                                    </sup>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                        <template v-slot="scope">
                                            <div v-if="scope.row.returnCalculation && scope.row.returnCalculation.length > 0">
                                                <span 
                                                    :class="isFieldReturnOfRunCalc(scope.row, 'safety_stock', scope.$index) ? 'return-run-calc' : 'not-return-run-calc'"
                                                    :style="{'margin-bottom': (index !== scope.row.returnCalculation.length - 1) ? '10px' : '0'}" 
                                                    v-for="(obj, index) in scope.row.returnCalculation" :key="index">
                                                    {{ obj.safety_stock }}
                                                </span>
                                            </div>
                                            <span v-else class="not-return-run-calc">0</span>
                                        </template>
                                    </el-table-column>

                                    <!-- field hasil run calculation -->
                                    <el-table-column prop="eoq" label="EOQ" min-width="150"> 
                                        <!-- v-if="['Normal', 'Deterministik', 'Poisson', 'Minimasi Regret', 'Probabilistik Kerusakan Linear', 'Probabilistik Kerusakan Nonlinear'].includes(scope.row.metode[0])" -->
                                        <template v-slot:header>
                                            <div class="table-header-center">
                                                EOQ
                                                <el-tooltip class="item" effect="dark" placement="top">
                                                    <template #content>
                                                        Jumlah unit yang harus dipesan untuk mendapatkan <br>
                                                        keuntungan ekonomis serta kombinasi biaya pemesanan <br>
                                                        dan biaya penyimpanan yang paling minimum
                                                    </template>
                                                    <sup>
                                                        <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                            <i class="fa fa-exclamation-circle"></i>
                                                        </span>
                                                    </sup>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                        <template v-slot="scope">
                                            <div v-if="scope.row.returnCalculation && scope.row.returnCalculation.length > 0">
                                                <span 
                                                    :class="isFieldReturnOfRunCalc(scope.row, 'EOQ', scope.$index) ? 'return-run-calc' : 'not-return-run-calc'"
                                                    :style="{'margin-bottom': (index !== scope.row.returnCalculation.length - 1) ? '10px' : '0'}" 
                                                    v-for="(obj, index) in scope.row.returnCalculation" 
                                                    :key="index">
                                                    {{ obj.EOQ }}
                                                </span>
                                            </div>
                                            <span v-else class="not-return-run-calc">0</span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column prop="rop" label="ROP" min-width="100">
                                        <template v-slot:header>
                                            <div class="table-header-center">
                                                ROP
                                                <el-tooltip class="item" effect="dark" placement="top">
                                                    <template #content>
                                                        Re-Order Point sebagai batas posisi jumlah <br/>
                                                        stock di mana pembelian kembali material <br/>
                                                        harus dilakukan untuk mengisi kembali <br/>
                                                        stock gudang.
                                                    </template>
                                                    <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                        <i class="fa fa-exclamation-circle"></i>
                                                    </span>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                        <template v-slot="scope">
                                            <div v-if="scope.row.returnCalculation && scope.row.returnCalculation.length > 0">
                                                <span 
                                                    :class="isFieldReturnOfRunCalc(scope.row, 'ROP', scope.$index) ? 'return-run-calc' : 'not-return-run-calc'"
                                                    :style="{'margin-bottom': (index !== scope.row.returnCalculation.length - 1) ? '10px' : '0'}" 
                                                    v-for="(obj, index) in scope.row.returnCalculation" 
                                                    :key="index">
                                                    {{ obj.ROP }}
                                                </span>
                                            </div>
                                            <span v-else class="not-return-run-calc">0</span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column prop="uom" label="UOM" min-width="100">
                                        <template v-slot:header>
                                            <div class="table-header-center">
                                                UOM
                                                <el-tooltip class="item" effect="dark" content="Satuan yang digunakan dalam perhitungan"
                                                    placement="top">
                                                    <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                        <i class="fa fa-exclamation-circle"></i>
                                                    </span>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                        <template v-slot="scope">
                                            <span>{{ scope.row.unit_of_measurement || '-' }}</span>
                                        </template>
                                    </el-table-column>

                                    <!-- Status -->
                                    <el-table-column prop="status" label="Status" min-width="150">
                                        <template v-slot:header>
                                            <div class="table-header-center">
                                                Status
                                                <el-tooltip class="item" effect="dark"
                                                    content="Status kelengkapan data" placement="top">
                                                    <sup>
                                                        <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                            <i class="fa fa-exclamation-circle"></i>
                                                        </span>
                                                    </sup>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                        <template v-slot="scope">
                                            <el-tooltip v-if="!isComplete(scope.row)" class="status-tooltip"
                                                effect="dark" :content="getIncompleteFields(scope.row)">
                                                <span
                                                    class="status-incomplete">
                                                    Tidak Lengkap
                                                </span>
                                            </el-tooltip>
                                            <span v-else
                                                class="status-complete">
                                                Lengkap
                                            </span>
                                        </template>
                                    </el-table-column>

                                    <!-- Unit price -->
                                    <el-table-column prop="unit_price" label="Unit Price" min-width="250">
                                        <template v-slot:header>
                                            <div class="table-header-center">
                                                Unit Price
                                                <el-tooltip class="item" effect="dark"
                                                    content="Harga 1 UoM sparepart dalam satuan rupiah" placement="top">
                                                    <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                        <i class="fa fa-exclamation-circle"></i>
                                                    </span>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                        <template v-slot="scope">
                                            <el-input v-model="scope.row.unit_price" placeholder="Unit Price" clearable @input="formatRupiahAndHandleEveryField(scope.$index, scope.row.unit_price, 'unit_price')" :disabled="isFieldDisabled(scope.row, 'unit_price')" :class="{ 'incomplete-field': isFieldIncomplete(scope.row, 'unit_price') }"
                                            >
                                            </el-input>
                                        </template>
                                    </el-table-column>

                                    <!-- Fitting Lead Time -->
                                    <el-table-column prop="lead_time" label="Fitting Lead Time" min-width="250">
                                        <template v-slot:header>
                                            <div class="table-header-center">
                                                Lead Time
                                                <el-tooltip class="item" effect="dark" placement="top">
                                                    <template #content>  
                                                        Durasi waktu semenjak user (perencanaan pemeliharaan) <br/>
                                                        menginformasikan permintaan Spare Part <br/>
                                                        sampai dengan waktu kedatangannya (PR-GR).<br/>
                                                    </template>
                                                    <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                        <i class="fa fa-exclamation-circle"></i>
                                                    </span>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                        <template v-slot="scope">
                                            <el-input v-model="scope.row.lead_time" 
                                                placeholder="Lead Time" 
                                                clearable
                                                @input="updateStatus(scope.row)"
                                                :disabled="isFieldDisabled(scope.row, 'lead_time')"
                                                :class="{ 'incomplete-field': isFieldIncomplete(scope.row, 'lead_time') }"
                                                type="number" />
                                        </template>
                                    </el-table-column> 

                                    <!-- service level -->
                                    <el-table-column prop="service_level" label="Service Level" min-width="250">
                                        <template v-slot:header>
                                            <div class="table-header-center">Service Level %
                                                <el-tooltip class="item" effect="dark" placement="top">
                                                    <template #content>  
                                                        Kemampuan memberikan pelayanan kepada user <br/>
                                                        pemeliharaan yang diukur berdasarkan rasio <br/>
                                                        antara tingkat ketersediaan (availability) <br/>
                                                        Material Stock dengan frekuensi permintaan.
                                                    </template>
                                                    <sup>
                                                        <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                            <i class="fa fa-exclamation-circle"></i>
                                                        </span>
                                                    </sup>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                        <template v-slot="scope">
                                            <!-- <el-tooltip 
                                                v-if="scope.row.isRecentlyAdded" 
                                                class="status-tooltip" 
                                                effect="dark"
                                                placement="top">
                                                <template #content> 
                                                    Nilai ini baru saja ditambahkan dari hasil kalkulasi dan <br> belum berpengaruh pada EOQ, Safety Stock, dan ROP 
                                                </template>
                                                <el-input v-model="scope.row.service_level"
                                                    placeholder="Service Level"
                                                    clearable 
                                                    @input="formatRupiahAndHandleEveryField(scope.$index, scope.row.service_level, 'service_level')"
                                                    :disabled="isFieldDisabled(scope.row, 'service_level')"
                                                    :class="{ 
                                                        'incomplete-field': isFieldIncomplete(scope.row, 'service_level'),
                                                        'recently-added-value-in-field': scope.row.isRecentlyAdded 
                                                    }" 
                                                />
                                            </el-tooltip> -->

                                            <!-- @input="formatRupiahAndHandleEveryField(scope.$index, scope.row.service_level, 'service_level')" -->
                                            <el-input v-model="scope.row.service_level" placeholder="Service Level" clearable :disabled="isFieldDisabled(scope.row, 'service_level')" type="number" :class="{  'incomplete-field': isFieldIncomplete(scope.row, 'service_level'), 'recently-added-value-in-field': scope.row.isRecentlyAdded }" @input="updateStatus(scope.row)"/>
                                        </template>
                                    </el-table-column> 

                                    <!-- Stock Out Effect -->
                                    <el-table-column prop="stock_out_effect" label="Stock Out Effect" min-width="250">
                                        <template v-slot:header>
                                            <div class="table-header-center">
                                                Stock Out Effect
                                                <el-tooltip class="item" effect="dark" placement="top">
                                                     <template #content>
                                                        Ongkos kerugian akibat ketidaktersediaan <br />
                                                        sparepart per hari
                                                    </template>
                                                    <sup>
                                                        <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                            <i class="fa fa-exclamation-circle"></i>
                                                        </span>
                                                    </sup>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                        <template v-slot="scope">
                                            <el-input v-model="scope.row.stock_out_effect" clearable
                                                @input="formatRupiahAndHandleEveryField(scope.$index, scope.row.stock_out_effect, 'stock_out_effect')"
                                                placeholder="Stock Out Effect"
                                                :disabled="isFieldDisabled(scope.row, 'stock_out_effect')"
                                                :class="{ 'incomplete-field': isFieldIncomplete(scope.row, 'stock_out_effect') }" />
                                        </template>
                                    </el-table-column>

                                    <!-- Order Cost -->
                                    <el-table-column prop="order_cost" label="Order Cost" min-width="250">
                                        <template v-slot:header>
                                            <div class="table-header-center">
                                                Order Cost
                                                <el-tooltip class="item" effect="dark" placement="top">
                                                    <template #content> Harga tiap melakukan pemesanan <br>suatu spare part dalam satuan rupiah</template>
                                                    <sup>
                                                        <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                            <i class="fa fa-exclamation-circle"></i>
                                                        </span>
                                                    </sup>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                        <template v-slot="scope">
                                            <el-input v-model="scope.row.order_cost" clearable
                                                @input="formatRupiahAndHandleEveryField(scope.$index, scope.row.order_cost, 'order_cost')"
                                                placeholder="Order Cost"
                                                :disabled="isFieldDisabled(scope.row, 'order_cost')"
                                                :class="{ 'incomplete-field': isFieldIncomplete(scope.row, 'order_cost') }" />
                                        </template>
                                    </el-table-column>

                                    <!-- Holding Cost -->
                                    <el-table-column prop="holding_cost" label="Holding Cost" min-width="200">
                                        <template v-slot:header>
                                            <div class="table-header-center">
                                                Holding Cost %
                                                <el-tooltip class="item" effect="dark" placement="top">
                                                    <template #content> Harga penyimpanan suatu spare part dalam
                                                            <br> satuan rupiah. Asumsi X % dari <br> harga
                                                            satuan</template>
                                                    <sup>
                                                        <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                            <i class="fa fa-exclamation-circle"></i>
                                                        </span>
                                                    </sup>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                        <template v-slot="scope">
                                            <el-input v-model="scope.row.holding_cost" clearable
                                                @input="updateStatus(scope.row)" placeholder="Holding Cost"
                                                :disabled="isFieldDisabled(scope.row, 'holding_cost')"
                                                type="number"
                                                :class="{ 'incomplete-field': isFieldIncomplete(scope.row, 'holding_cost') }">
                                            </el-input>
                                        </template>
                                    </el-table-column>

                                    <!-- Jumlah Komponen Terpasang -->
                                    <el-table-column prop="jumlah_komponen_terpasang" label="Jumlah Komponen Terpasang"
                                        min-width="270">
                                        <template v-slot:header>
                                            <div class="table-header-center">
                                                Jumlah Komponen Terpasang
                                                <el-tooltip class="item" effect="dark"
                                                    placement="top">
                                                    <template #content> Jumlah suatu sparepart yang terpasang <br />
                                                    dalam seluruh equipment yang sedang <br />
                                                    bekerja dalam satuan unit
                                                    </template>
                                                    <sup>
                                                        <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                            <i class="fa fa-exclamation-circle"></i>
                                                        </span>
                                                    </sup>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                        <template v-slot="scope">
                                            <el-input v-model="scope.row.jumlah_komponen_terpasang" clearable
                                                @input="formatRupiahAndHandleEveryField(scope.$index, scope.row.jumlah_komponen_terpasang, 'jumlah_komponen_terpasang')"
                                                placeholder="Jumlah Komponen Terpasang"
                                                :disabled="isFieldDisabled(scope.row, 'jumlah_komponen_terpasang')"
                                                :class="{ 
                                                    'incomplete-field': isFieldIncomplete(scope.row, 'jumlah_komponen_terpasang'), 
                                                }"
                                            />
                                        </template>
                                    </el-table-column>

																		<!-- Initial Inventory -->
																		<el-table-column prop="current_stock" label="Initial Inventory"
                                        min-width="270">
                                        <template v-slot:header>
                                            <div class="table-header-center">
                                                Initial Inventory
                                                <!-- <el-tooltip class="item" effect="dark"
                                                    placement="top">
                                                    <template #content> Jumlah suatu sparepart yang terpasang <br />
                                                    dalam seluruh equipment yang sedang <br />
                                                    bekerja dalam satuan unit
                                                    </template>
                                                    <sup>
                                                        <span style="font-size:10px; cursor: pointer; margin-left: 5px;">
                                                            <i class="fa fa-exclamation-circle"></i>
                                                        </span>
                                                    </sup>
                                                </el-tooltip> -->
                                            </div>
                                        </template>
                                        <template v-slot="scope">
                                            <el-input v-model="scope.row.current_stock" clearable 
                                                placeholder="Initial Inventory"
                                                disabled
                                            />
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div v-else-if="loadingCalculate">
                                <skeleton-loading></skeleton-loading>                            
                            </div>

                            <div class="d-flex flex-row justify-content-end align-items-center mt-3">
                                <base-button v-if="!loadingSkeleton && !isUploading && tableUpload.length > 0" size="md" :type="isCalculationButtonDisabled ? 'dark' : 'success'" :disabled="isCalculationButtonDisabled"  @click="runCalculation" :loading="loadingCalculate" >
                                    Run Calculation
                                    <el-tooltip placement="top">
                                        <template #content>
                                            Run Calculation dapat diklik saat<br>
                                            kolom Status tidak ada yang tidak lengkap 
                                        </template>
                                        <sup>
                                            <span style="font-size:10px"><i class="fa fa-exclamation-circle"></i>
                                            </span>
                                        </sup>
                                    </el-tooltip>
                                </base-button>
                                <div class="ml-3">
                                    <base-button 
                                        v-if="!loadingSkeleton && !isUploading && tableUpload.length" 
                                        size="md" 
                                        :type="!isDownloadButtonEnabled ? 'dark' : 'primary'"
                                        :disabled="!isDownloadButtonEnabled" 
                                        @click="handleDownload"
                                    >
                                        Download
                                        <el-tooltip placement="top">
                                            <template #content>
                                                Download dataset berdasarkan anak <br>
                                                perusahaan dan nomor material yang dipilih
                                            </template>
                                            <sup>
                                                <span style="font-size:10px"><i class="fa fa-exclamation-circle"></i>
                                                </span>
                                            </sup>
                                        </el-tooltip>
                                    </base-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- pop up dialog matrix -->
                    <el-dialog
                        title="Matrix Perhitungan"
                        :visible.sync="matrixDialogVisible"
                        width="70%"
                        :close-on-click-modal="false"
                        @close="handleDialogClose"
                    >
                        <div class="table-container">
                        <table class="matrix-table">
                            <thead>
                            <tr>
                                <th rowspan="4" class="variable-header" style="width: 180px">Variable</th>
                                <th colspan="8" class="method-header">METODE</th>
                                <th rowspan="4" class="bcr-header" style="background-color: #1579ab; width: 90px">BCR</th>
                            </tr>
                            <tr>
                                <th colspan="3" class="sub-header">N=0</th>
                                <th class="sub-header">N &lt; 30</th>
                                <th class="sub-header">N &gt;= 30</th>
                                <th colspan="3" class="sub-header">N &lt; 30 &amp; N &gt; 30</th>
                            </tr>
                            <tr>
                                <th colspan="3" class="sub-header" style="background-color: #1579ab"> NON MOVING (PT) </th>
                                <th class="sub-header" style="background-color: #1579ab; font-weight: bold;"> Poisson (PP) </th>
                                <th class="sub-header" style="background-color: #1579ab; font-weight: bold;; width: 60px"> Normal (PN) </th>
                                <th class="sub-header" style="background-color: #1579ab; font-weight: bold;"> Deterministik (MD) </th>
                                <th class="sub-header" colspan="2" style="background-color: #1579ab; font-weight: bold;"> Tak Tentu (MT) </th>
                            </tr>
                            <tr>
                                <th class="sub-header" style="width: 70px; font-weight: bold;">Regret</th>
                                <th class="sub-header" style="width: 100px; font-weight: bold;">Liniear Kerusakan</th>
                                <th class="sub-header" style="width: 120px; font-weight: bold;"> Non Linear Kerusakan </th>
                                <th class="sub-header" style="width: 75px; font-weight: bold;">Poisson</th>
                                <th class="sub-header" style="width: 75px; font-weight: bold;">Model Q</th>
                                <th class="sub-header" style="width: 110px; font-weight: bold;">Deterministik</th>
                                <th class="sub-header" style="width: 80px; font-weight: bold;">Tchebycheff</th>
                                <th class="sub-header" style="width: 80px; font-weight: bold;">MinMax</th>
                            </tr>
                            <tr>
                                <th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> Output </th>  
                                <th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> SS, TCO </th>
                                <th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> SS, TCO </th>
                                <th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> SS, TCO </th>
                                <th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> EOQ, ROP, SS </th>
                                <th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> EOQ, ROP, SS, Service Level, TCO </th>
                                <th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> EOQ, ROP, TCO </th>
                                <th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> EOQ, TCO </th>
                                <th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> SS, ROP, EOQ, TCO </th>
                                <th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> Rekomendasi Pembelian Sparepart </th>
                            </tr>
                            </thead>
                            <tbody class="text-dark" style="font-weight: bold;">
                            <tr>
                                <td>*Demand (D)</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>*Lead Time (L)</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td></td>
                                <td>✓</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>*Unit Price (p)</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>**Stock Effect (Cu)</td>
                                <td></td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td></td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>**Order Cost (A)</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td></td>
                                <td>✓</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>**Holding Cost (h)</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>**Suku Bunga (i)</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>**Jumlah Komponen Terpasang (n)</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>***Serice Level</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td></td>
                                <td>✓</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Sisa Tahun Pemakaian</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>✓</td>
                            </tr>
                            </tbody>
                        </table>
                        <h5>
                            Keterangan: <br> N merupakan jumlah adanya pengambilan sejak tahun 2018 dalam satuan waktu bulan <br> *Data terisi otomatis dari SAP (editable) <br> **Data menggunakan fixed asumsi bersifat prefilled (editable) <br> ***Data merupakan output perhitungan dan berdifat editable (recalculate) <br> 
                            <div>
                                <div>
                                    <span class="text-white my-0 py-0" style="background-color: #1579ab">&nbsp;&nbsp;&nbsp;Metode Return Value From API&nbsp;&nbsp;&nbsp;</span>
                                </div>
                                <div>
                                    <span class="text-white my-0 py-0" style="background-color: #1ad0b7">&nbsp;&nbsp;&nbsp;Output Masing-Masing Metode&nbsp;&nbsp;&nbsp;</span>
                                </div>
                            </div>
                        </h5>
                        </div>
                        <span slot="footer" class="dialog-footer">
                        <el-button @click="matrixDialogVisible = false">Tutup</el-button>
                        </span>
                    </el-dialog>

                    <!-- Dialog untuk upload file -->
                    <el-dialog 
                        title="Input Materials Form" 
                        :visible.sync="uploadDialogVisible" 
                        width="30%"
                        :before-close="cancelUpload" 
                        :close-on-click-modal="false"
                    >
                        <!-- Gunakan Data TA -->
                        <div class="mb-3">
                            <label class="form-control-label d-flex align-items-center">
                                <b>Gunakan Data TA</b>
                                <span class="text-danger">* </span>
                                <el-tooltip content="Data z61 terkait kebutuhan sparepart untuk TA" placement="top">
                                    <sup class="ml-1">
                                        <i class="fa fa-exclamation-circle" style="font-size: 10px;"></i>
                                    </sup>
                                </el-tooltip>
                            </label>
                            <base-input name="Data TA" rules="required">
                                <el-select v-model="dataTa" class="w-100" placeholder="Gunakan Data TA"
                                    :disabled="loadingCalculate > 0">
                                    <el-option label="Ya" value="True"></el-option>
                                    <el-option label="Tidak" value="False"></el-option>
                                </el-select>
                            </base-input>
                        </div>

                        <!-- Anak Perusahaan -->
                        <div class="mb-3">
                            <label class="form-control-label d-flex align-items-center">
                                <b>Anak Perusahaan</b>
                                <span class="text-danger">* </span>
                                <el-tooltip content="Anak perusahaan dalam PI Grup" placement="top">
                                    <sup class="ml-1">
                                        <i class="fa fa-exclamation-circle" style="font-size: 10px;"></i>
                                    </sup>
                                </el-tooltip>
                            </label>
                            <base-input name="Anak Perusahaan" rules="required">
                                <el-select v-model="selectedPlant" class="w-100" placeholder="Pilih Plant"
                                    :disabled="loadingCalculate || ['B000', 'C000', 'D000', 'E000', 'F000', 'G000', 'H000', 'I000', 'J000'].includes(authUser.company_code)">
                                    <el-option v-for="plant in plants" :key="plant.code" :value="plant.code"
                                        :label="plant.name"></el-option>
                                </el-select>
                            </base-input>
                        </div>

                        <div class="mb-3">
                            <label class="form-control-label d-flex align-items-center">
                                <b>Material Number</b>
                                <span class="text-danger">* </span>
                                <el-tooltip placement="top">
                                    <template #content>Material Spare Part MRO (6XXXXX)</template>
                                    <sup>
                                        <span style="font-size:10px">
                                            <i class="fa fa-exclamation-circle"></i>
                                        </span>
                                    </sup>
                                </el-tooltip>
                            </label>
                            <base-input name="Material Number" rules="required">
                                <textarea :disabled="loadingCalculate" class="form-control" rows="10"
                                    v-model="multipleMatNum" @input="fixCursor($event)">
                                </textarea>
                            </base-input>
                        </div>

                        <span slot="footer" class="dialog-footer">
                            <el-button @click="cancelUpload" :disabled="isUploading">Batal</el-button>
                            <el-button 
                                type="primary" 
                                @click="submitUploadForm"
                                :loading="isUploading"
                            >
                                Upload
                            </el-button>
                        </span>
                    </el-dialog>
                </div>
            </div>

        </div>
        <noaccess v-else />
    </div>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
// import Chart from 'chart.js/dist/chart.js'
import Swal from 'sweetalert2';
import SkeletonLoading from '../../components/SkeletonLoading.vue';
import configUrl from '@/configs/config'
import * as XLSX from 'xlsx'; // Import the xlsx module

export default {
    components: { SkeletonLoading },
    data() {
        return {
            // State dan kontrol UI
            dragging: false,
            startY: 0,
            showTable: false,
            startHeight: 0,
            showThirdTable: true,
            anotherTableData: [],

            editDialogVisible: false,
            resetDialogVisible: false,
            loadingCalculate: false,

            loadingFittingData: false,
            selectedPlant: null,
            dataTa: null,
            multipleMatNum: '',
            tableUpload: [],
            // fittingDataResult: [],
            plants: [
                { code: 'B601', name: 'PKG' },
                { code: 'C601', name: 'PKC' },
                { code: 'D601', name: 'PKT' },
                { code: 'E601', name: 'PIM' },
                { code: 'F601', name: 'PSP' }
            ],

            uploadForm: {
                file: null
            },
            maxValidation: {
                text: 'Select file with extension (.xlsx, .xls)',
                size: 5 // Maximum file size in MB
            },
            calculationResults: [],
            showFirstTable: true,
            matrixDialogVisible: false,
            table1Height: 500, // Atur tinggi tabel sesuai kebutuhan
            uploadDialogVisible: false,
            isCalculationDisabled: true,
            selectedRows: [], // Untuk menyimpan baris yang dipilih
            isCalculationButtonDisabled: true, // Inisialnya dinonaktifkan
            isCalculated: false, // Variabel untuk menandakan apakah perhitungan sudah dilakukan
            isUploading: false, // State untuk loading pada tombol "Upload"
            loadingSkeleton: false,
            initialReturnCalculation: [],
            initialMetode: [],
            apiUrl: configUrl.apiUrl,
            stockStrategyData: []
        }
    },
    computed: {
        ...mapState({
            authUserPermission: state => state.auth.userPermission,
            authUserRole: state => state.auth.userRole,
						authUser: state => state.auth.user
        }),

        // edit by sahrul
        tableHeight() {
            // Tinggi tabel disesuaikan agar scroll muncul ketika data lebih dari 5
            return this.tableUploud.length > 100 ? '250px' : 'auto';
        },
        isButtonDisabled() {
            return this.tableUpload.some(row => !this.isComplete(row));
        },
        isDownloadButtonEnabled() {
            return this.selectedRows.length > 0; // Tombol Download diaktifkan jika ada baris yang dipilih
        },
        // end edit 
    },
    watch: {
    },
    mounted() {
        this.getStockStrategyFromCsv();
				this.initializeSelectedPlant();
    },
    methods: {
				initializeSelectedPlant() {
						const mapping = {
							B000: 'B601',
							C000: 'C601',
							D000: 'D601',
							E000: 'E601',
							F000: 'F601',
							G000: 'G601',
							H000: 'H601',
							I000: 'I601',
							J000: 'J601',
						};

						// Cek apakah company_code ada dalam mapping
						if (this.authUser.company_code in mapping) {
							this.selectedPlant = mapping[this.authUser.company_code];
							// console.log('selectedPlant', this.selectedPlant)
						}
				},
        // Fungsi untuk menangani formulir upload
        submitUploadForm() {
						// console.log("submitUploadForm", this.selectedPlant, this.dataTa, this.multipleMatNum)
            // const file = this.uploadForm.file;
            if (!!this.selectedPlant && !!this.dataTa && !!this.multipleMatNum) {
                this.loadingSkeleton = true // Tampilkan skeleton loading saat proses upload
                this.isUploading = true;

                const arrayMatNum = this.multipleMatNum.split(/[\n,]+/).map(val => val.replace(/[" ,]/g, '').trim()).filter(val => val !== '')

                if (arrayMatNum.length > 0) {
                    const duplicateItems = arrayMatNum.filter((item, index) => arrayMatNum.indexOf(item) !== index);

                    if (duplicateItems.length > 0) {
                        this.$notify({
                            message: "Failed, due to duplication in the Material Number field",
                            type: "danger",
                            verticalAlign: "bottom",
                            horizontalAlign: "left"
                        });
                        this.isUploading = this.loadingSkeleton = false // Tampilkan skeleton loading saat proses upload
                        
                        return false
                    } else {
                        this.tableUpload = arrayMatNum.map((item, key) => ({
                            no: key + 1,
                            material_code: item,
                            plant: '',
                            unit_price: 0,
                            kategori: '',
                            deskripsi: '',
                            stock_out_effect: 0,
                            holding_cost: 0,
                            lead_time: 0,
                            lead_time_max: 0,
                            lead_time_min: 0,
                            order_cost: 0,
                            yearly_demand_rate: 0,
                            standar_deviasi: 0,
                            unit_of_measurement: '',
                            service_level: 0,
                            jumlah_komponen_terpasang: 5,
                            stockNonStock: '',
                            metode: [],
                            total_cost_optimal: 0,
                            isRecentlyAdded: false,
                            returnCalculation: []
                        }));

                        // mulai fitting data
                        this.handleFittingData();
                    }
                }
            } else {
                this.$notify({
                    message: "Please complete the Anak Perusahaan field, Data TA, and Material Number",
                    type: "danger",
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
            }
        },
        async handleFittingData() {
            this.loadingFittingData = true;
            this.isUploading = true;

            const useDataTa = this.dataTa === 'True';
            const material_codes = this.tableUpload.map(item => String(item.material_code));
            const postData = {
                "use_data_ta": useDataTa,
                "plant": this.selectedPlant,
                "material_codes": material_codes
            };

            try {
                const response = await axios.post('https://stock-calc-be-v2.pupuk.in/fit-data', postData, {
                    headers: { "Content-Type": "application/json" }
                });
                
                response.data.forEach((itemFitting, key) => {
                    let inputValuesInTableUpload = this.tableUpload.find(itemTable => itemTable.material_code === itemFitting.material)
                    if (inputValuesInTableUpload) {

                        // inputValuesInTableUpload.kategori                   = ['Deterministik', 'Normal', 'Poisson'].includes(itemFitting.kategori) ? '' + itemFitting.kategori : ['Tak Tentu'].includes(itemFitting.kategori) ? 'Tak Tentu' : itemFitting.kategori
                        inputValuesInTableUpload.kategori                   = itemFitting.kategori
                        inputValuesInTableUpload.deskripsi                  = itemFitting.deskripsi
                        inputValuesInTableUpload.plant                      = this.selectedPlant
                        inputValuesInTableUpload.unit_price                 = this.plainFormatRupiah(itemFitting.unit_price ?? 0)
                        inputValuesInTableUpload.stock_out_effect           = this.plainFormatRupiah(itemFitting.stock_out_effect ?? 3700000000)
                        inputValuesInTableUpload.service_level              = ['Tak Tentu'].includes(itemFitting.kategori) ? 90 : 0

                        if (itemFitting.kategori !== 'Non Moving') {
                            inputValuesInTableUpload.holding_cost           = itemFitting.holding_cost ?? 15
                            inputValuesInTableUpload.lead_time              = parseFloat(itemFitting.lead_time ? itemFitting.lead_time.toFixed(2) : 0)
                            // inputValuesInTableUpload.lead_time              = itemFitting.lead_time ? itemFitting.lead_time : 0
                            inputValuesInTableUpload.lead_time_min          = itemFitting.lead_time_min ? itemFitting.lead_time_min : 0
                            inputValuesInTableUpload.lead_time_max          = itemFitting.lead_time_max ? itemFitting.lead_time_max : 0
                            inputValuesInTableUpload.lead_time_std          = itemFitting.lead_time_std ? itemFitting.lead_time_std : 0
                            inputValuesInTableUpload.order_cost             = this.plainFormatRupiah(this.calculateOrderCost(itemFitting.unit_price ?? 0))  
                            inputValuesInTableUpload.material_category      = itemFitting.material_category ? itemFitting.material_category : ''
                            inputValuesInTableUpload.jumlah_komponen_terpasang = ""
                            inputValuesInTableUpload.mean_demand            = itemFitting.mean_demand ? itemFitting.mean_demand : 0
                            inputValuesInTableUpload.std_demand             = itemFitting.std_demand ? itemFitting.std_demand : 0
                            inputValuesInTableUpload.min_demand             = itemFitting.min_demand ? itemFitting.min_demand : 0
                        } else {
                            inputValuesInTableUpload.holding_cost           = ''
                            inputValuesInTableUpload.lead_time              = ''
                            inputValuesInTableUpload.lead_time_min          = ''
                            inputValuesInTableUpload.lead_time_max          = ''
                            inputValuesInTableUpload.order_cost             = ''
                        }
                        inputValuesInTableUpload.current_stock              = itemFitting.current_stock ?? 0
                        inputValuesInTableUpload.yearly_demand_rate         = itemFitting.yearly_demand_rate ?? 0
                        inputValuesInTableUpload.standar_deviasi            = itemFitting.standar_deviasi ?? 0
                        inputValuesInTableUpload.unit_of_measurement        = itemFitting.unit_of_measurement ?? ''

                    }
                })

                console.log('response fitting data', response.data, this.tableUpload)
                
                // Update status tombol Run Calculation
                this.updateCalculationButtonStatus();

                // Cek jika ada satu material yang lengkap
                const isThereCompleteData = this.tableUpload.some(row => this.isComplete(row));
                if (isThereCompleteData) {

                    // yang komplit dirun calculation
                    await this.runCalculation();

                } else {
                    this.$notify({
                        message: "Tidak ada satupun material hasil fitting data yang lengkap.",
                        type: "danger",
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                }
            } catch (error) {
                this.$notify({
                    message: error.message ?? "Error during Data Fitting. Ensure the entered material number has no quotation marks",
                    type: "danger",
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
            } finally {
                this.loadingFittingData = false;
                this.isUploading = false;
                this.uploadDialogVisible = false;
                this.loadingSkeleton = false;
            }
        },
        async runCalculation() {
            this.loadingCalculate = true
            // const filteredData = this.fittingDataResult
            const filteredData = this.tableUpload
                .filter(row => this.isComplete(row)) // Hanya ambil baris yang lengkap
                .map(row => {

                    const yearlyDemandRate  = row.yearly_demand_rate || 0;
                    const standarDeviasi    = row.standar_deviasi || 0;

                    // console.log('infilter data', yearlyDemandRate, row)

                    // Membuat objek parameter input
                    const inputParameter = {
                        // lead_time: row.lead_time ? parseFloat(row.lead_time)/12 : 0,
                        lead_time: row.lead_time ? parseFloat(row.lead_time) : 0,
                        unit_price: row.unit_price ? this.parseIntFromRupiahOrNotNumberVal(row.unit_price) : 0,
                        order_cost: row.order_cost ? this.parseIntFromRupiahOrNotNumberVal(row.order_cost) : 0,
                        holding_cost: row.holding_cost ? parseInt(String(row.holding_cost).replace('%', '')) : 0,
                        jumlah_komponen_terpasang: row.jumlah_komponen_terpasang ? parseInt(row.jumlah_komponen_terpasang, 10) : 0,
                        stock_out_effect: row.stock_out_effect ? this.parseIntFromRupiahOrNotNumberVal(row.stock_out_effect) : 0,
                        ...(['Normal', 'Deterministik', 'Poisson', 'Tak Tentu'].includes(row.kategori) ? { material_category: row.material_category, current_stock: parseFloat(row.current_stock) } : {}),
                        // jika kategori pola tak tentu maka ada penambahan key
                        ...( row.kategori === 'Tak Tentu' ? { lead_time_max: row.lead_time_max ? parseFloat(row.lead_time_max) : 0 , lead_time_min: row.lead_time_min ? parseFloat(row.lead_time_min) : 0 , lead_time_std: row.lead_time_std ? parseFloat(row.lead_time_std) : 0 , mean_demand: row.mean_demand ? parseFloat(row.mean_demand) : 0 , std_demand: row.std_demand ? parseFloat(row.std_demand) : 0 , min_demand: row.min_demand ? parseFloat(row.min_demand) : 0 } : {}),
                        // jika type yearly demand rate number
                        ... (typeof yearlyDemandRate === 'number' && !isNaN(yearlyDemandRate) && yearlyDemandRate !== undefined ? { yearly_demand_rate: yearlyDemandRate, standar_deviasi: standarDeviasi} : {}),
                        // ... (this.isCalculated && row.service_level ? { service_level: parseFloat(row.service_level)} : {})
                        ... (row.service_level ? { service_level: parseFloat(row.service_level) } : {})
                    };

                    // VALIDASI INPUTTAN
                    if (['Normal', 'Deterministik', 'Poisson', 'Tak Tentu'].includes(row.kategori) && inputParameter.unit_price === 0) {
                        this.$notify({
                            message: 'Unit Price pada ' + String(row.material_code) + ' tidak boleh 0',
                            type: 'danger',
                            verticalAlign: "bottom",
                            horizontalAlign: "left"
                        });
                        this.loadingCalculate = false;
                        return false
                    }
                    if (['Normal', 'Deterministik', 'Poisson'].includes(row.kategori) && inputParameter.holding_cost === 0) {
                            this.$notify({
                                message: 'Holding Cost pada ' + String(row.material_code) + ' tidak boleh 0',
                                type: 'danger',
                                verticalAlign: "bottom",
                                horizontalAlign: "left"
                            });
                            this.loadingCalculate = false;
                            return false   
                    }
                    if (['Normal', 'Poisson'].includes(row.kategori) && inputParameter.order_cost === 0) {
                            this.$notify({
                                message: 'Order Cost pada ' + String(row.material_code) + ' tidak boleh 0',
                                type: 'danger',
                                verticalAlign: "bottom",
                                horizontalAlign: "left"
                            });
                            this.loadingCalculate = false;
                            return false   
                    }
                    if (row.kategori === 'Normal' && inputParameter.stock_out_effect === 0) {
                            this.$notify({
                                message: 'Stock Out Effect ' + String(row.material_code) + ' tidak boleh 0',
                                type: 'danger',
                                verticalAlign: "bottom",
                                horizontalAlign: "left"
                            });
                            this.loadingCalculate = false;
                            return false   
                    }
                    if (row.kategori === 'Non Moving' && inputParameter.jumlah_komponen_terpasang === 0) {
                        this.$notify({
                            message: 'Komponen Terpasang ' + String(row.material_code) + ' tidak boleh 0',
                            type: 'danger',
                            verticalAlign: "bottom",
                            horizontalAlign: "left"
                        });
                        this.loadingCalculate = false;
                        return false
                    }

                    // format run calculation
                    return {
                        plant: this.selectedPlant,
                        material: String(row.material_code),
                        kategori: row.kategori,
                        input_parameter: inputParameter
                    };
                }).filter(row => row !== null);

            if (filteredData.length === 0) {
                this.$notify({
                    message: 'No valid data',
                    type: 'danger',
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
                return false;
            }

            console.log('before runCalculation', filteredData)

            try {
                const response = await axios.post('https://stock-calc-be-v2.pupuk.in/calculate', JSON.stringify(filteredData), {
                    headers: { "Content-Type": "application/json" }
                });

                // deklarasi variable
                const processedMaterials = new Set()
                // let hasAdditServiceLevel = false
                this.calculationResults  = response.data
                this.tableUpload.forEach(item => {
                    item.returnCalculation = []
                    item.metode = []
                })
                processedMaterials.clear()

                console.log('after runCalculation', response.data)
                // response.data.forEach(resultCalcItem => {
                for(const resultCalcItem of response.data) {
                    const tableUploadIndex = this.tableUpload.findIndex(fittingItem => fittingItem.material_code === resultCalcItem.material)
                    if (tableUploadIndex !== -1) {
                        
                        //Get Stock Strategy
                        if (!processedMaterials.has(resultCalcItem.material)) {
                            processedMaterials.add(resultCalcItem.material);

                            // ambil summary stock or non stock
                            const stockResponse = this.stockStrategyData.find(item => item.material_code === parseInt(resultCalcItem.material, 10));
                            stockResponse ? this.tableUpload[tableUploadIndex].stockNonStock = stockResponse.display_stock_strategy : this.tableUpload[tableUploadIndex].stockNonStock = 'Belum Dianalisa SHP'    
                        }

                        const returnCalcObj = {
                            // safety_stock: parseFloat(resultCalcItem.safety_stock ? resultCalcItem.safety_stock.toFixed(2) : 0),
                            safety_stock: parseFloat(resultCalcItem.safety_stock ? Math.ceil(resultCalcItem.safety_stock) : 0),
                            EOQ: resultCalcItem.EOQ ?? 0,
                            ROP: resultCalcItem.ROP ?? 0,
                            total_cost_optimal: resultCalcItem.kategori === 'Tak Tentu' ? resultCalcItem.total_biaya : resultCalcItem.total_cost_optimal ?? 0
                        }
                        // masukkan response.data ke tableUpload array tertentu
                        this.tableUpload[tableUploadIndex].returnCalculation.push(returnCalcObj)

                        // masukkan metode ke tableUpload metode array tertentu
                        let metode = resultCalcItem.metode === 'Probabilistik Kerusakan Linear' ? 'Prob. Kerusakan Linear' : resultCalcItem.metode === 'Probabilistik Kerusakan Nonlinear' ? 'Prob. Kerusakan Nonlinear' : resultCalcItem.metode
                        this.tableUpload[tableUploadIndex].metode.push(metode || '-')

												// Jika ada service level, maka diisi dari return calc
												if (resultCalcItem.service_level) {
                            this.tableUpload[tableUploadIndex].service_level = resultCalcItem.service_level;
                        }

                        this.$set(this.tableUpload, tableUploadIndex, this.tableUpload[tableUploadIndex])
                    }
                }

                //  cek apakah ada tambahan service level dan disable tombol 'Run Calculation'
                const isThereIncompleteDataRow = this.tableUpload.some(row => !this.isComplete(row))
                if (isThereIncompleteDataRow) {
                    this.isCalculationButtonDisabled = true;
                } else {
                    this.isCalculationButtonDisabled = false;
                }

                // notif muncul
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Run Calculation runs successfully. There might be changes in Safety Stock, EOQ", 
                    // text: "Run Calculation runs successfully. There might be changes in Safety Stock, EOQ and ROP", 
                });

                // posisikan layar setelah run calculation di id "stare-after-run-calc"
                const targetElement = document.getElementById("stare-after-run-calc");
                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
                }

            } catch (error) {
                this.isCalculationButtonDisabled = true
                this.$notify({
                    message: error.message +  (error.message === 'Network Error' ? '. The run calculation process was stopped' : '') ,
                    type: "danger",
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
            } finally {
                this.loadingCalculate = false
            }
        },
        isComplete(row) {
            const kategori = row.kategori;
            if(!kategori){
                return false
            }
            // Tentukan fields yang diperlukan berdasarkan kategori
            const requiredFields = {
                'Tak Tentu': ['unit_price', 'stock_out_effect'],
                'Non Moving': ['jumlah_komponen_terpasang', 'unit_price', 'stock_out_effect'],
                'Poisson': ['lead_time', 'unit_price', 'stock_out_effect', 'order_cost', 'holding_cost'],
                'Deterministik': ['lead_time', 'unit_price', 'stock_out_effect', 'order_cost', 'holding_cost'],
                'Normal': ['lead_time', 'unit_price', 'stock_out_effect', 'order_cost', 'holding_cost']
            }[kategori] || [];

            const assessment = requiredFields.every(field => {
                                    const value = row[field] 
                                    return value && value.toString().trim() !== '';
                                })
            return assessment;
        }, 
        getFittingValue(material_code, key) {
            const fittingItem = this.tableUpload.find(item => String(item.material) === String(material_code));
            return fittingItem ? fittingItem[key] ?? '' : '';
        },
        // Mendapatkan nilai dari hasil perhitungan berdasarkan material_code dan key
        getCalculationValue(material_code, key) {
            // const calculationItem = this.fittingDataResult.find(item => String(item.material) === String(material_code));
            const calculationItem = this.calculationResults.find(item => String(item.material) === String(material_code));
            return calculationItem ? calculationItem[key] : '';
        },
        // Validasi status
        updateStatus(row) {
            this.$forceUpdate(); // Paksa pembaruan UI jika diperlukan
            this.updateCalculationButtonStatus(); // Cek apakah lengkap semua, kalau lengkap, calculation button on
        },
        plainFormatRupiah(value) {
            return this.parseIntFromRupiahOrNotNumberVal(value).toLocaleString('id-ID', {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 0,
            }).replace('Rp', 'Rp.');
        }, 
        formatRupiahAndHandleEveryField(key, value, field) {
            //FORMAT RUPIAH
            if (['unit_price', 'order_cost', 'stock_out_effect'].includes(field)) {
                this.tableUpload[key][field] = String(value)
                const beingInt               = this.parseIntFromRupiahOrNotNumberVal(value) //harus string yang dilempar
                const formattedValue         = beingInt.toLocaleString('id-ID', {
                                                    style: 'currency',
                                                    currency: 'IDR',
                                                    minimumFractionDigits: 0,
                                                }).replace('Rp', 'Rp.');

                setTimeout(() => {
                    this.tableUpload[key][field] = formattedValue

                    // PENGGANTI FUNCTION handleUnitPriceChange()
                    if (field === 'unit_price') {
                        this.tableUpload[key].order_cost = this.calculateOrderCost(beingInt).toLocaleString('id-ID', {
                                                                style: 'currency',
                                                                currency: 'IDR',
                                                                minimumFractionDigits: 0,
                                                            }).replace('Rp', 'Rp.');
                        this.updateStatus(this.tableUpload[key]);
                    }
                    if (field === 'order_cost') {
                        this.updateStatus(this.tableUpload[key])                
                    }
                    if (field === 'stock_out_effect') {
                        this.updateStatus(this.tableUpload[key])                
                    }
                }, 0)
            }
            // HANDLING JUMLAH KOMPONEN TERPASANG
            if (field === 'jumlah_komponen_terpasang') {
                if (value === 0) {
                    this.tableUpload[key][field] = ''
                } else {
                    this.tableUpload[key][field] = value
                }
                this.updateStatus(this.tableUpload[key])
            }
        },
        parseIntFromRupiahOrNotNumberVal(value) {
            const cleanedValue = String(value).replace(/[^0-9]/g, '')
            return parseInt(cleanedValue, 10) || 0;
        },
        updateCalculationButtonStatus() {
            // Aktifkan tombol jika ada data lengkap, dan tombol disable jika salah satu row ada yang tidak lengkap 
            this.isCalculationButtonDisabled = this.tableUpload.some(row => !this.isComplete(row)); 
        },
        // Fungsi untuk memeriksa apakah field tertentu dinonaktifkan
        isFieldDisabled(row, field) {
            const kategori = row.kategori;
            const usedFields = {
                'Tak Tentu': ['unit_price', 'lead_time', 'stock_out_effect', 'order_cost', 'holding_cost', 'service_level'],
                'Non Moving': ['jumlah_komponen_terpasang', 'unit_price', 'stock_out_effect', 'service_level'],
                'Poisson': ['lead_time', 'unit_price', 'stock_out_effect', 'order_cost', 'holding_cost', 'service_level'],
                'Deterministik': ['lead_time', 'unit_price', 'stock_out_effect', 'order_cost', 'holding_cost', 'service_level'],
                'Normal': ['lead_time', 'unit_price', 'stock_out_effect', 'order_cost', 'holding_cost', 'service_level']
            }[kategori] || [];

            return !usedFields.includes(field);
        },
        // Fungsi untuk memeriksa apakah field tertentu tidak lengkap
        isFieldIncomplete(row, field) {
            const kategori        = row.kategori;
            const isFieldDisabled = this.isFieldDisabled(row, field)
            if(!kategori && !isFieldDisabled){
                return true
            } else if(!kategori && isFieldDisabled) {
                return false
            }

            const requiredFields = {
                'Tak Tentu': ['unit_price', 'stock_out_effect', 'holding_cost'],
                'Non Moving': ['jumlah_komponen_terpasang', 'unit_price', 'stock_out_effect'],
                'Poisson': ['lead_time', 'unit_price', 'stock_out_effect', 'order_cost', 'holding_cost'],
                'Deterministik': ['lead_time', 'unit_price', 'stock_out_effect', 'order_cost', 'holding_cost'],
                'Normal': ['lead_time', 'unit_price', 'stock_out_effect', 'order_cost', 'holding_cost']
            }[kategori] || [];

            return requiredFields.includes(field) && !row[field];
        },
        isFieldReturnOfRunCalc(row, field, key) {   
            const metode = row.metode[0]
            const availFields = {
                'Deterministik': ['EOQ', 'ROP', 'metode'],
                'Poisson': ['safety_stock', 'EOQ', 'ROP' , 'metode'],
                'Normal': ['safety_stock', 'EOQ', 'ROP' , 'metode'],
                'MinMax': ['safety_stock', 'ROP', 'EOQ', 'metode'],
                'Tchebycheff': ['safety_stock', 'metode'],
                'Minimasi Regret': ['safety_stock', 'metode'],
                'Probabilistik Kerusakan Linear': ['safety_stock', 'metode'],
                'Probabilistik Kerusakan Nonlinear': ['safety_stock', 'metode'],
            }[metode] || []

            return availFields.includes(field)
        },
        getIncompleteFields(row) {
            const kategori = row.kategori;
            if (!kategori) {
                return [];
            }

            if (kategori) {
                // Tentukan fields yang diperlukan berdasarkan kategori
                const requiredFields = {
                    'Tak Tentu': ['unit_price', 'stock_out_effect', 'holding_cost'],
                    'Non Moving': ['jumlah_komponen_terpasang', 'unit_price', 'stock_out_effect'],
                    'Poisson': ['lead_time', 'unit_price', 'stock_out_effect', 'order_cost', 'holding_cost'],
                    'Deterministik': ['lead_time', 'unit_price', 'stock_out_effect', 'order_cost', 'holding_cost'],
                    'Normal': ['lead_time', 'unit_price', 'stock_out_effect', 'order_cost', 'holding_cost']
                }[kategori] || [];

                const incompleteFields = requiredFields.filter(field => {
                    const value = row[field];
                    return !value || value.toString().trim() === '';
                });

                return incompleteFields.length > 0 ? `Belum Terdapat Data Pada: ${incompleteFields.join(', ')}` : '';
            } else {
                return 'material tidak memiliki kategori';
            }
        },
        computeTableHeight() 
        {
            return this.table1Height; // Example function, adjust as needed
        },
        openInputMaterialsDialog() {
            this.uploadDialogVisible = true; // Buka dialog upload saat tombol ditekan
            this.dataTa = null,
            this.multipleMatNum = ''
        },
        cancelUpload() {
            this.uploadDialogVisible = false; 
            this.isUploading = false;
        },
        // Fungsi untuk menghitung Order Cost
        calculateOrderCost(unitPriceVal) {
            return unitPriceVal > 100000000 ? 5000000 : 1000000;
        },
        // download template
        openDownloadDialog() {
            const url = '/assets/smartcat/template_downlod.xlsx';
            const link = document.createElement('a');
            link.href = url;
            link.download = 'template_downlod.xlsx'; // Nama file yang akan diunduh
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        handleSelectionChange(selection) {
            this.selectedRows = selection; // Memperbarui data baris yang dipilih
        },
        handleDownload() {
            const data = this.selectedRows.map(row => ({
                no: row.no,
                material: row.material_code ? row.material_code : '-',
                anak_perusahaan: this.selectedPlant ? this.selectedPlant : '-',
                deskripsi: row.deskripsi ? row.deskripsi : '-',
                stock_strategy: row.stockNonStock ? row.stockNonStock : '-',
                metode: this.formatMultipleValExcel(row.metode.map(metode => metode)),
                safety_stock: this.formatMultipleValExcel(row.returnCalculation.map(calculation => calculation.safety_stock ?? 0)),
                EOQ: this.formatMultipleValExcel(row.returnCalculation.map(calculation => calculation.EOQ ?? '-')),
                unit_of_measurement: row.unit_of_measurement ? row.unit_of_measurement : '-',
                // ROQ: this.formatMultipleValExcel(row.returnCalculation.map(calculation => calculation.ROQ ?? '-')),
                ROP: this.formatMultipleValExcel(row.returnCalculation.map(calculation => calculation.ROP ?? '-')),
                unit_price: row.unit_price ? row.unit_price : '-',
                lead_time: row.lead_time ? row.lead_time : '-',
                service_level: row.service_level ? row.service_level : '-',
                stock_out_effect: row.stock_out_effect ? row.stock_out_effect : '-',
                order_cost: row.order_cost ? row.order_cost : '-',
                holding_cost: row.holding_cost ? row.holding_cost : '-',
                jumlah_komponen_terpasang: row.jumlah_komponen_terpasang ? row.jumlah_komponen_terpasang : '-',
                // total_cost_optimal: this.formatMultipleValExcel(row.returnCalculation.map(calculation => this.plainFormatRupiah(parseFloat(calculation.total_cost_optimal.toFixed(2))) ?? '-'))
            }));

            // Buat workbook dan sheet
            const ws = XLSX.utils.json_to_sheet(data);

            // Melakukan text wrapping (jika ada )
            Object.keys(ws).forEach(cell => {
                if (typeof ws[cell] === 'object' && ws[cell].v !== undefined) {
                    if (ws[cell].s) {
                        ws[cell].s.alignment = { wrapText: true };
                    } else {
                        ws[cell].s = { alignment: { wrapText: true } };
                    }
                }
            });

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            const now = new Date()
            const formattedDate = now.toISOString().slice(0, 19).replace(/[-T:]/g, '_')
            // Simpan file Excel
            XLSX.writeFile(wb, `multiple_matnum_data_${formattedDate}.xlsx`);

            this.$notify({
                message: 'Download file berhasil.',
                type: "success",
                verticalAlign: 'bottom',
                horizontalAlign: 'left'
            });
        },
        formatMultipleValExcel(values) {
            if (values.length === 1) {
                return values[0];
            }

            return values.map((value, index) => `${index + 1}. ${value}`).join('\n');
        },
        // matrik perhitungan
        openMatrixDialog() {
            this.matrixDialogVisible = true;
        },
        handleDialogClose() {
            // console.log('Dialog Perhitungan ditutup')
        },
        getStockStrategyFromCsv() {
            const fileCsvPath = '/assets/source_stock_calc_single_material_stock_or_non_stock/Rekapan_SHP_Material.xlsx'
            
            fetch(fileCsvPath).then(response => {
                if (!response.ok) {
                    this.$notify({
                        message: "File Stock Strategy tidak ditemukan.",
                        type: "danger",
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                    return false
                }
                return response.arrayBuffer();
            })
            .then(arrayBuffer => {
                const data = new Uint8Array(arrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0]; // Mengambil sheet pertama
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);

                if (jsonData.length === 0) {
                    // this.$message.warning('File yang diambil kosong.');
                    // throw new Error('File kosong');
                    this.$notify({
                        message: "File yang diambil kosong.",
                        type: "danger",
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                    return false
                }

                this.stockStrategyData = jsonData.map((item, index) => ({
                    no: index + 1,
                    material_code: item['Material Code'],
                    description: item['Description'],
                    anper: item['Anper'],
                    stock_strategy: item['Stock Strategy'],
                    display_stock_strategy: item['Display Stock Strategy']
                }))
            })
            .catch(error => {
                 this.$notify({
                    message: 'Kesalahan memproses file: ' + error,
                    type: "danger",
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
            });
        },
    }
};
</script>

<style scoped>
/* css by sahrul */

/* border tidak lengkap */
.incomplete-field {
    background-color: #f8d7da;
    /* Warna latar belakang merah muda */
    border: 1px solid #f5c6cb;
    /* Warna border merah muda */
}

.recently-added-value-in-field {
    background-color: #abfc90;
    /* Warna latar belakang merah muda */
    border: 1px solid #abfc90;
    /* Warna border merah muda */
}

/* tabel swap */
.table-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

.table-container {
    overflow: hidden;
    transition: height 0.2s ease;
}

.drag-handle {
    background-color: #ccc;
    height: 10px;
    cursor: row-resize;
    position: relative;
    width: 100%;
    margin: 0;
}
/* end  */

.colorValueMetode {
    color: green; 
    font-weight: bold
}

/* pop up matrix */
.table-container {
  overflow-x: auto;
  /* margin-bottom: 1rem; */
}

.matrix-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-size: 10px; /* Ukuran font lebih kecil */
  min-width: 600px; /* Lebar minimum lebih kecil */
}

.matrix-table th,
.matrix-table td {
  border: 1px solid #0d0909; /* Border lebih tipis */
  padding: 5px; /* Padding lebih kecil */
}

.matrix-table th {
  background-color: #2a4f8a;
  color: white;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
}

.variable-header {
  background-color: #009688;
}

.method-header {
  background-color: #2196f3;
}

.sub-header {
  background-color: #64b5f6;
}

.bcr-header {
  background-color: #ffc107;
}

.matrix-table tbody td {
  background-color: #f9f9f9;
}

.matrix-table tbody tr:nth-child(even) td {
  background-color: #fff;
}

.table-header-center {
  text-align: center;
  font-weight: bold;
}

/* dashboard multiple */
.button-group base-button:hover {
    opacity: 0.9;
}

.button-group base-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

/* lengkap &tidak lengkap */
.status-tooltip .el-tooltip__popper {
    max-width: 200px;
    word-wrap: break-word;
}


.status-complete {
    background-color: #d4edda;
    /* Warna hijau muda */
    color: #155724;
    /* Warna teks hijau gelap */
    padding: 5px;
    border-radius: 3px;
}

.status-incomplete {
    background-color: #f8d7da;
    /* Warna merah muda */
    color: #721c24;
    /* Warna teks merah gelap */
    padding: 5px;
    border-radius: 3px;
}


/* end  sahrul*/

.return-run-calc {
    /* Warna hijau muda */
    background-color: #d4edda;
    /* Warna teks hijau gelap */
    color: #000000;
    padding-left: 5px;
    display: inline-block;
    width: 100%;
    /* box-sizing: border-box; */
    border-radius: 3px;
}

.not-return-run-calc {
    background-color: #d0d0d0;
    /* Warna hijau muda */
    color: #000000;
    /* Warna teks hijau gelap */
    padding-left: 5px;
    display: inline-block;
    width: 100%;
    /* box-sizing: border-box; */
    border-radius: 3px;
}

.el-tooltip__popper {
    max-width: 320px !important;
    word-break: break-word;
}

.customTableKecil tbody tr th,
td {
    padding: 6px !important;
    text-align: center;
}

.errorRow {
    background-color: #ffe391 !important;
}

@media (max-width: 768px) {
  .matrix-table {
    font-size: 8px; /* Ukuran font lebih kecil pada layar kecil */
  }

  .matrix-table th,
  .matrix-table td {
    padding: 4px; /* Padding lebih kecil pada layar kecil */
  }

  .matrix-table th,
  .matrix-table td {
    white-space: nowrap; /* Mencegah teks membungkus pada ukuran kecil */
  }
}
</style>